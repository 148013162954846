import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Flex } from '@chakra-ui/react';
import { LatestReadings } from 'components/LatestReadings';
import { Loading } from 'components/Loading';
import { Scanner } from 'components/Scanner';
import { WarningText } from 'components/Warning/WarningText';

import { Nav } from '../../components/Nav';

export const Scan = () => {
  const { state }: { state: { id: string; type: 'Event' | 'BenefitProgram' } } = useLocation();
  const { id, type } = state;

  const navigate = useNavigate();

  const [permission, setPermission] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    !state && navigate('/', { replace: true });
  }, [navigate, state]);

  useEffect(() => {
    const getPermissions = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });

        setPermission(true);
      } catch (error) {
        setPermission(false);
        setError(true);
      } finally {
        setIsLoaded(true);
      }
    };

    getPermissions();
  }, []);

  return (
    <>
      <Nav button={true} />

      <LatestReadings id={id} type={type} />

      {!isLoaded && <Loading text='Carregando scanner' />}

      {error && (
        <Flex maxW='500px' mx='auto' p={{ base: '6', md: '0' }} direction='column' gap='6'>
          <WarningText
            title='Permissão de câmera'
            description='Para desfrutar de todas as funcionalidades do aplicativo, é fundamental permitir o acesso à câmera. Sem essa permissão, algumas características podem ficar indisponíveis. Garanta uma experiência completa habilitando o uso da câmera.'
          />
        </Flex>
      )}

      {permission && <Scanner event={id} type={type} />}
    </>
  );
};
