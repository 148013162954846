export interface IUser {
  _id?: string;
  btixBalance?: number;
  cpf?: string;
  createdAt?: string;
  email?: string;
  lastName?: string;
  name?: string;
  updatedAt?: string;
  userRoles?: string;
  producerInfo?: IProducerInfo;
  resource?: IResource;
  appleId?: string;
  avatarPath?: string;
  googleId?: string;
}
export interface IProducerInfo {
  producerDescription: string;
  producerWhatsapp: string;
  producerTwitterLink: string;
  producerInstagramLink: string;
  producerFacebookLink: string;
}
export interface IResource {
  name: string;
  url: string;
}

export interface IEvent {
  _id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  description: string;
  address: string;
  startDate: string;
  endDate: string;
  eventStartHour: string;
  lineUp: string[];
  producer: IUser;
  location: ILocation;
  resource: IResource;
}
export interface ILocation {
  type?: string;
  coordinates?: number[];
  city?: string;
  state?: string;
  country?: string;
}

export enum ITicketStatus {
  READY = 'Ready',
  CONSUMED = 'Consumed',
  CANCELED = 'Canceled',
  REVERSED = 'Reversed',
  WAITING_APPROVAL = 'WAITING_APPROVAL',
}
