import { useNavigate } from 'react-router-dom';

import { Flex, Image, Text, Skeleton, Heading } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';

interface ICardEvent {
  data: any;
  type: 'Event' | 'BenefitProgram';
}
export const Card = ({ data, type }: ICardEvent) => {
  const navigate = useNavigate();

  return (
    <Flex
      bg='secondary.400'
      w='100%'
      maxW='600px'
      h='300px'
      mb='30px'
      mx='auto'
      p='6'
      borderRadius='10px'
      overflow='hidden'
      direction='column'
      justify='space-between'
      onClick={() => navigate('/escanear', { state: { id: data?._id, type } })}
    >
      <Heading fontSize='20px' fontWeight='600' color='white' noOfLines={2}>
        {data?.name}
      </Heading>

      {data?.resource?.url ?? data?.imageUrl ? (
        <Image
          src={data?.resource?.url ?? data?.imageUrl}
          alt={data?.name}
          w='100%'
          h='172px'
          objectFit='cover'
          objectPosition='center'
        />
      ) : (
        <Flex w='100%' h='172px' bg='gray.500' align='center' justify='center' overflow='hidden'>
          <Text
            color='gray.300'
            fontWeight='600'
            textTransform='uppercase'
            opacity='0.6'
            position='absolute'
            zIndex='10'
          >
            Sem Imagem
          </Text>
          <Skeleton boxSize='100%' />
        </Flex>
      )}

      <Text fontSize='18px' noOfLines={3} color='white' textAlign='center'>
        {data?.benefits ? (
          `${data?.description}`
        ) : (
          <>
            <Text as='span'>Data: </Text>
            {format(parseISO(data?.startDate.toString()), 'dd/MM/yy')} a{' '}
            {format(parseISO(data?.endDate.toString()), 'dd/MM/yy')}.
          </>
        )}
      </Text>
    </Flex>
  );
};
