import { useEffect, useRef, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import {
  Box,
  Center,
  Flex,
  Heading,
  Text,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  Badge,
} from '@chakra-ui/react';
import { formatDate, normalizeDocumentNumber } from 'helpers/format';
import { clearReadings, getReadings } from 'helpers/reading';
import { ITicketStatus } from 'interfaces/interfaces';
import { Reading } from 'interfaces/reading';

export const statusMap: Record<ITicketStatus, string> = {
  [ITicketStatus.READY]: 'Ingresso válido',
  [ITicketStatus.CONSUMED]: 'Ingresso já foi consumido.',
  [ITicketStatus.CANCELED]: 'Ingresso inválido.',
  [ITicketStatus.REVERSED]: 'Compra foi estornada.',
  [ITicketStatus.WAITING_APPROVAL]: 'Ingresso pode estar em transferência.',
};

interface LatestReadingsProps {
  id: string;
  type: 'Event' | 'BenefitProgram';
}

export const LatestReadings = ({ id, type }: LatestReadingsProps) => {
  const buttonRef: any = useRef();

  const [readings, setReadings] = useState<Reading[]>([]);
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setReadings(getReadings(id));
    }, 1000);

    setReadings(getReadings(id));

    return () => clearInterval(intervalId);
  }, [confirmation, id]);

  const handleClean = () => {
    if (!confirmation) {
      return setConfirmation(true);
    } else {
      setConfirmation(false);
      clearReadings();
      onCloseDrawer();

      return;
    }
  };

  return (
    <>
      <Flex w='100%' justify='center' p='6'>
        <Box
          w='100%'
          maxW='500px'
          h='60px'
          px='4'
          borderRadius='10px'
          bg='secondary.400'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          cursor='pointer'
          ref={buttonRef}
          onClick={onOpenDrawer}
        >
          <Heading
            fontSize='24px'
            letterSpacing='0.5px'
            textAlign='center'
            textTransform='uppercase'
          >
            Últimas Leituras{' '}
          </Heading>
          <Box w='30px' bg='primary.500' borderRadius='10px'>
            <Center h='100%' justifyContent='center'>
              <Text fontWeight='600' fontSize='20px'>
                {readings?.length}
              </Text>
            </Center>
          </Box>
        </Box>
      </Flex>

      <Drawer
        isOpen={isOpenDrawer}
        placement='bottom'
        size='full'
        onClose={onCloseDrawer}
        finalFocusRef={buttonRef}
      >
        <DrawerOverlay />
        <DrawerContent bg='secondary.400' p='6' h='90%'>
          <DrawerHeader p='0' mb='6'>
            <Flex w='100%' h='40px' align='center' justify='center'>
              <Flex w='15%' />

              <Flex w='70%' justify='center'>
                <Text textTransform='uppercase'>Últimas Leituras</Text>
              </Flex>

              <Flex w='15%' justify='flex-end'>
                <Button onClick={onCloseDrawer} w='40px' h='40px' bg='primary.500' p='0'>
                  <AiOutlineClose size={18} color='white' />
                </Button>
              </Flex>
            </Flex>
          </DrawerHeader>

          <DrawerBody px='0' py='6'>
            {readings.length > 0 ? (
              <Accordion allowToggle>
                {readings.map((ticket, index) => (
                  <AccordionItem border='none' mb='2' key={index}>
                    <h2>
                      <AccordionButton
                        border='none'
                        _expanded={{ bg: 'secondary.500', color: 'white' }}
                        _hover={{ bg: 'secondary.600' }}
                        _active={{ bg: 'secondary.500' }}
                      >
                        <Box as='span' flex='1' textAlign='left'>
                          {ticket.ticket ?? 'Ingresso não Encontrado'}
                          <Badge
                            variant='subtle'
                            colorScheme={
                              ticket.status === ITicketStatus.READY
                                ? 'green'
                                : ticket.status === ITicketStatus.CONSUMED
                                ? 'orange'
                                : 'red'
                            }
                            ml='10px'
                          >
                            {ticket.status === ITicketStatus.READY ? 'Liberada' : 'Bloqueada'}
                          </Badge>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <VStack align='flex-start'>
                        {/* Status */}
                        <Text
                          color={ticket.status === ITicketStatus.READY ? 'success' : 'error.500'}
                        >
                          <Text as='span' fontWeight='bold' letterSpacing='0.5px' color='white'>
                            Entrada:{' '}
                          </Text>
                          {ticket.status === ITicketStatus.READY ? 'Liberada' : 'Bloqueada'}
                        </Text>

                        {/* Owner Name */}
                        <Text>
                          <Text as='span' fontWeight='bold' letterSpacing='0.5px'>
                            Nome:{' '}
                          </Text>
                          {ticket?.owner ?? 'Nome não encontrado.'}
                        </Text>

                        {/* CPF */}
                        {type === 'BenefitProgram' && (
                          <Text>
                            <Text as='span' fontWeight='bold' letterSpacing='0.5px'>
                              CPF:{' '}
                            </Text>
                            {normalizeDocumentNumber(ticket?.cpf) ?? 'CPF não encontrado.'}
                          </Text>
                        )}

                        {/* Ticket */}
                        <Text>
                          <Text as='span' fontWeight='bold' letterSpacing='0.5px'>
                            Ingresso:{' '}
                          </Text>
                          {ticket?.ticket ?? 'Ingresso não Encontrado.'}
                        </Text>

                        {/* Ticket Status */}
                        {ticket.status !== 'Ready' && (
                          <Text>
                            <Text as='span' fontWeight='bold' letterSpacing='0.5px'>
                              Motivo:{' '}
                            </Text>
                            {statusMap[ticket.status as ITicketStatus] ?? 'Desconhecido'}
                          </Text>
                        )}

                        {/* Consumed at */}
                        <Text>
                          <Text as='span' fontWeight='bold' letterSpacing='0.5px'>
                            {ticket?.consumedAt ? 'Leitura feita em: ' : ''}
                          </Text>
                          {formatDate(ticket?.consumedAt, `HH:mm 'do dia' dd MMMM'`)}
                        </Text>
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            ) : (
              <Text fontSize='18px' letterSpacing='0.4px' fontWeight='600' textAlign='center'>
                Nenhum ingresso foi lido no momento.
              </Text>
            )}
          </DrawerBody>

          <DrawerFooter p='0' mt='6'>
            <Flex w='100%' justify='space-between'>
              <Button
                w='48%'
                bg='gray.600'
                color='white'
                border='none'
                transition='.3s'
                _hover={{ bg: 'gray.700' }}
                onClick={() => {
                  onCloseDrawer();
                  setConfirmation(false);
                }}
              >
                Fechar
              </Button>
              <Button
                w='48%'
                bg='primary.500'
                color='white'
                border='none'
                transition='.3s'
                _hover={{ bg: 'primary.700' }}
                onClick={handleClean}
                isDisabled={readings.length < 1}
              >
                {confirmation ? 'Confirmar' : 'Limpar'}
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
