import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Button,
  VStack,
  Heading,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Header } from 'components/Header';

import { useAuth } from '../../hooks/auth';
import { ICredentials } from '../../hooks/types';
import { signInFormSchema } from './schema';

const defaultValues: ICredentials = {
  email: '',
  password: '',
};

export const Login = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { SignIn, isLoading } = useAuth();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ICredentials>({
    defaultValues,
    resolver: yupResolver(signInFormSchema),
  });

  const handleSignIn = async (credential: ICredentials) => {
    try {
      await SignIn(credential);
    } catch (error) {
      setShowAlert(true);
    } finally {
      setTimeout(setShowAlert, 3000, false);
    }
  };

  return (
    <Flex w='100%' maxW='100vw' h='100%' minH='100vh' direction='column'>
      <Header />

      <Flex
        as='form'
        direction='column'
        p='6'
        align='center'
        justify='center'
        mt='50px'
        onSubmit={handleSubmit(handleSignIn)}
      >
        <VStack spacing='30px' w='100%' maxW='450px' zIndex='100'>
          <Heading fontSize='24px' textAlign='center' color='white'>
            Entre na sua conta
          </Heading>

          <FormControl variant='floating' isInvalid={!!errors.email}>
            <Input
              variant='filled'
              placeholder=' '
              autoComplete='off'
              onInput={(input: any) => (input.target.value = input?.target?.value.toLowerCase())}
              {...register('email')}
              style={{ transition: '.3s' }}
            />

            <FormLabel htmlFor='email'>E-mail</FormLabel>

            <FormErrorMessage variant='simple'>{errors.email?.message}</FormErrorMessage>
          </FormControl>

          <FormControl variant='floating' isInvalid={!!errors.password}>
            <InputGroup>
              <Input
                type={isPasswordVisible ? 'text' : 'password'}
                variant='filled'
                placeholder=' '
                autoComplete='off'
                {...register('password')}
                style={{ transition: '.3s' }}
              />

              <FormLabel htmlFor='password'>Senha</FormLabel>

              <InputRightElement
                children={isPasswordVisible ? <MdVisibility /> : <MdVisibilityOff />}
                aria-label='toggle password visibility'
                fontSize='xl'
                h='100%'
                w={12}
                color='gray.400'
                cursor='pointer'
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              />
            </InputGroup>

            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          </FormControl>

          {showAlert && (
            <Alert status='error' color='primary.500' borderRadius='10px'>
              <AlertIcon />
              E-mail ou senha incorretos!
            </Alert>
          )}

          <Button
            bg='primary.500'
            color='white'
            type='submit'
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            Entrar
          </Button>
        </VStack>
      </Flex>
    </Flex>
  );
};
