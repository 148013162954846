import axios, { AxiosError } from 'axios';

import { TOKEN_KEY } from '../constants';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL as string,
});

api.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem(TOKEN_KEY);
    if (token !== null && !config.headers?.Authorization) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    if (error instanceof AxiosError) {
      if (
        error.response?.data.message === 'Unauthorized' ||
        (error.response?.data.statusCode === 401 && error.response?.data.message === 'Unauthorized')
      ) {
        error.response.data.message = 'Faça login para continuar';
        localStorage.clear();

        window.location.reload();
      }
    }

    return Promise.reject(error);
  },
);
export default api;
