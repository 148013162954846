import { Flex, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import Logo from '../../assets/images/logo.png';
import Show from '../../assets/images/show.png';

export const Header = () => {
  return (
    <>
      <Flex w='100%' overflow='hidden'>
        <Flex position='absolute' zIndex='0' left='0' bottom='50%'>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Image src={Show} w='100vw' />
          </motion.div>
        </Flex>

        <Flex
          w='100%'
          h={{ base: '60%', md: '70%' }}
          position='absolute'
          zIndex='0'
          style={{ background: 'linear-gradient(to top,  #333333 30%, transparent 100%)' }}
        />
      </Flex>

      <Flex w='100%' h='100px' bg='transparent' justify='center' align='center' zIndex={5}>
        <Image src={Logo} w='120px' h='45px' />
      </Flex>
    </>
  );
};
