/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef, useState } from 'react';

import { Center, Flex, useDisclosure } from '@chakra-ui/react';
import { BrowserQRCodeReader, NotFoundException, VideoInputDevice } from '@zxing/library';
import { Dotted } from 'components/Dotted';
import { Loading } from 'components/Loading';
import { ScanResult } from 'components/ScanResult';
import { SelectVideo } from 'components/SelectVideo';
import { addReading } from 'helpers/reading';
import { ITicketStatus } from 'interfaces/interfaces';
import { scanTicket } from 'services/ticket';

interface ScannerProps {
  event: string;
  type: 'Event' | 'BenefitProgram';
}

export const Scanner = ({ event, type }: ScannerProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isDecoding, setIsDecoding] = useState<boolean>(false);
  const [errorInDevice, setErrorInDevice] = useState<boolean>(false);

  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');
  const [codeReader, setCodeReader] = useState<BrowserQRCodeReader>();
  const [ticketRead, setTicketRead] = useState<any>(null);
  const [devices, setDevices] = useState<any>([]);

  const cancelRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const video = document.getElementById('video') as HTMLVideoElement;
    decode();

    if (video && selectedDeviceId) {
      const constraints = {
        video: { deviceId: selectedDeviceId },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          video.srcObject = stream;
          video.play();
        })
        .catch((error) => {
          setErrorInDevice(true);
          console.error('Erro ao acessar a câmera:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeviceId]);

  useEffect(() => {
    const codeReader = new BrowserQRCodeReader();
    setCodeReader(codeReader);

    const isMobileDevice = (): boolean => {
      const userAgent = navigator.userAgent;
      return /Mobi/.test(userAgent);
    };

    const isMobile = isMobileDevice();

    const startDecoding = async () => {
      try {
        const videoInputDevices: VideoInputDevice[] = await codeReader.getVideoInputDevices();
        setDevices(videoInputDevices);

        const firstCamera = isMobile
          ? videoInputDevices[1]
          : videoInputDevices.find((device) => device.kind === 'videoinput');

        if (firstCamera) {
          setSelectedDeviceId(firstCamera.deviceId);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoaded(true);
      }
    };

    startDecoding();
  }, []);

  const decode = async () => {
    try {
      if (!codeReader || !selectedDeviceId) return;

      let ticket;
      const result = (
        await codeReader.decodeOnceFromVideoDevice(selectedDeviceId, 'video')
      ).getText();

      try {
        setIsDecoding(true);
        const response = await scanTicket(event, result, type);

        response.status = ITicketStatus.READY;
        ticket = response;
      } catch (error: any) {
        const { status, data } = error.response;

        if (status === 400) {
          ticket = data.statusCode === 400 ? { status: ITicketStatus.CANCELED } : data;
        }
      } finally {
        addReading({
          _id: ticket?._id,
          consumedAt: ticket?.consumedAt,
          event: ticket?.event?._id,
          status: ticket?.status,
          owner: `${ticket?.owner?.name} ${ticket?.owner?.lastName}`,
          cpf: `${ticket?.owner?.cpf ?? ticket?.owner?.documentNumber}`,
          ticket: `${ticket?.ticketType?.name} ${ticket?.lot?.name}` ?? 'Ingresso não encontrado',
        });

        setTicketRead(ticket);
        onOpen();
        setIsDecoding(false);
      }
    } catch (error: any) {
      handleDecodeError(error);
    }
  };

  const handleDecodeError = (error: Error) => {
    if (error instanceof NotFoundException) {
      console.warn('Nenhum QR code encontrado.');
    } else {
      console.error('Erro ao decodificar:', error);
    }
  };

  return (
    <>
      {isLoaded && (
        <SelectVideo
          devices={devices}
          currentDevice={selectedDeviceId}
          setDevice={setSelectedDeviceId}
        />
      )}

      <Center px='6'>
        <Flex w='fit-content' maxW='500px' height='100%' position='relative'>
          <video id='video' width='100%' height='200' autoPlay></video>
          {isLoaded && !errorInDevice && <Dotted />}
        </Flex>
      </Center>

      {isDecoding && <Loading text='Lendo QR Code' />}

      <ScanResult
        setTicket={setTicketRead}
        ticket={ticketRead}
        cancelRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
        decode={decode}
      />
    </>
  );
};
