import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const formatDate = (date?: string | Date | null, mask?: string) => {
  if (date) {
    return format(date instanceof Date ? date : parseISO(date), mask || 'dd/MM/yyyy', {
      locale: ptBR,
    });
  }

  return date;
};

export const normalizeDocumentNumber = (value: string) => {
  const badChars = /[^\d]/g;
  const mask = /(\d{3})(\d{3})(\d{3})(\d{2})/;
  const cpf = new String(value).replace(badChars, '');
  return cpf.replace(mask, '$1.$2.$3-$4').substring(0, 14);
};
