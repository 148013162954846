import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
  VStack,
  Image,
  Box,
  Center,
  Skeleton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';

import Logo from '../../assets/images/logo.svg';
import { useAuth } from '../../hooks/auth';

interface IHeader {
  button?: boolean;
}

interface ILogout {
  isOpen: boolean;
  setIsOpen: boolean | any;
}

export const ConfirmLogout = ({ isOpen, setIsOpen }: ILogout) => {
  const { signOut } = useAuth();

  return (
    <Modal size='xs' isCentered isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
      <ModalContent bg='secondary.400' w='80%' borderRadius='20'>
        <ModalHeader>
          <Flex w='100%' justifyContent='flex-end'>
            <Button
              onClick={() => setIsOpen(false)}
              w='20px'
              h='40px'
              p='0'
              border='none'
              bg='primary.500'
            >
              <AiOutlineClose size={18} color='white' />
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Text fontSize='18px' fontWeight='500' textAlign='center'>
            Deseja sair da sua conta?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex w='100%' justify='space-between'>
            <Button
              onClick={() => {
                setIsOpen(false);
                signOut();
              }}
              bg='secondary.500'
              w='45%'
              color='white'
              transition='.5s'
              _hover={{ borderColor: 'secondary.500', bg: 'secondary.600' }}
            >
              Sim
            </Button>
            <Button
              onClick={() => setIsOpen(false)}
              bg='primary.500'
              w='45%'
              color='white'
              transition='.5s'
              _hover={{ borderColor: 'primary.500', bg: 'primary.400' }}
            >
              Cancelar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const Nav = ({ button = false }: IHeader) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Flex
      as='header'
      w='100%'
      h={{ base: '99px', md: '87px' }}
      bg='secondary.500'
      justify='space-between'
      align='center'
      borderBottom='1px solid #454444'
      p='6'
      zIndex='100'
    >
      <Flex w='30%'>
        <Flex onClick={() => navigate('/')} cursor='pointer' width='30%'>
          <Flex display={button ? 'flex' : 'none'} align='center'>
            <Box
              as='button'
              boxSize='26px'
              bg='#FD6244'
              outline='none'
              border='none'
              borderRadius='8px'
              marginRight='10px'
              _hover={{ outline: 'none', border: 'none' }}
              _focus={{ outline: 'none', border: 'none' }}
            >
              <Center>
                <ChevronLeftIcon boxSize='20px' />
              </Center>
            </Box>
            <Text
              fontWeight='600'
              transition='.3s'
              _hover={{ color: 'primary.500' }}
              _focus={{ color: 'primary.500' }}
            >
              Voltar
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex w='40%' justify='center'>
        <Flex w='120px' h='45px' align='center' justify='center'>
          <Image src={Logo} w='100%' />
        </Flex>
      </Flex>

      <Flex w='30%' justify='flex-end' align='center'>
        <Menu>
          {/* Avatar */}
          <MenuButton
            bg='secondary.400'
            transition='all .2s'
            borderRadius={['50%', '100px']}
            borderColor='secondary.500'
          >
            <Flex align='center' justify='space-between'>
              {!user ? (
                <>
                  <Box boxSize='36px'>
                    <Skeleton boxSize='100%' borderRadius='50%' />
                  </Box>

                  <Skeleton
                    display={{ base: 'none', md: 'flex' }}
                    w='90px'
                    h='20px'
                    mx='10px'
                    opacity='.4'
                  />
                </>
              ) : (
                <>
                  <Avatar
                    src={
                      user?.resource?.url
                        ? user?.resource?.url
                        : user?.avatarPath
                        ? user?.avatarPath
                        : ''
                    }
                    name={`${user?.name} ${user?.lastName}`}
                    boxSize='36px'
                  />

                  <Text
                    display={{ base: 'none', md: 'flex' }}
                    fontSize='sm'
                    fontWeight='600'
                    mx='10px'
                  >
                    {user?.name} {user?.lastName}
                  </Text>
                </>
              )}

              <Box
                pr='10px'
                outline='none'
                border='none'
                display={{ base: 'none', md: 'flex' }}
                _hover={{ outline: 'none', border: 'none' }}
                _focus={{ outline: 'none', border: 'none' }}
              >
                <Center>
                  <FiChevronDown color='#6A6868' size='20' />
                </Center>
              </Box>
            </Flex>
          </MenuButton>

          {/* List */}
          <MenuList bg='secondary.400' border='none' zIndex='110' p='0'>
            <VStack p='2' pb='0'>
              <Avatar
                size='2xl'
                src={
                  user?.resource?.url
                    ? user?.resource?.url
                    : user?.avatarPath
                    ? user?.avatarPath
                    : ''
                }
                name={`${user?.name} ${user?.lastName}`}
              />

              <Text fontWeight='600' pt='10px' letterSpacing='0.5px'>
                {user?.name} {user?.lastName}
              </Text>
            </VStack>

            <MenuDivider my='2' />

            <VStack spacing='1' p='2' pt='0'>
              <MenuItem
                border='none'
                _hover={{ outline: 'none', bg: 'gray.700', color: 'primary.500' }}
                transition='background .2s'
                bg='secondary.400'
                borderRadius='10px'
                onClick={() => navigate('/')}
              >
                Início
              </MenuItem>

              <MenuItem
                border='none'
                _hover={{ outline: 'none', bg: 'gray.700', color: 'primary.500' }}
                transition='background .2s'
                bg='secondary.400'
                borderRadius='10px'
                onClick={() => setIsOpen(true)}
              >
                Sair
              </MenuItem>
            </VStack>
          </MenuList>
        </Menu>
      </Flex>

      <ConfirmLogout isOpen={isOpen} setIsOpen={setIsOpen} />
    </Flex>
  );
};
