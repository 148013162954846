import { Link } from 'react-router-dom';

import { Flex, Text } from '@chakra-ui/react';

export const PageNotFound = () => {
  return (
    <Flex
      w='100vw'
      minH='100vh'
      h='100%'
      justify='center'
      align='center'
      direction='column'
      color='primary.500'
    >
      <Text fontSize='3xl' color='white'>
        Página não encontrada.
      </Text>
      <Link to='/inicio' style={{ color: '#FD6244' }}>
        Ir para início.
      </Link>
    </Flex>
  );
};
