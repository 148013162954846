import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
} from '@chakra-ui/react';
import { normalizeDocumentNumber } from 'helpers/format';
import { ITicketStatus } from 'interfaces/interfaces';

interface ScanResultProps {
  setTicket: (value: any) => void;
  ticket: any;
  cancelRef: any;
  isOpen: boolean;
  onClose: () => void;
  decode: () => void;
}

export const statusMap: Record<ITicketStatus, { title: string; text: string; color: string }> = {
  [ITicketStatus.READY]: {
    title: 'VÁLIDO',
    text: 'Entrada LIBERADA',
    color: '#61e991',
  },
  [ITicketStatus.CONSUMED]: {
    title: 'CONSUMIDO',
    text: 'Ingresso já foi UTILIZADO',
    color: '#F9A82F',
  },
  [ITicketStatus.CANCELED]: {
    title: 'INVÁLIDO',
    text: 'Entrada BLOQUEADA',
    color: '#fa4949',
  },
  [ITicketStatus.REVERSED]: {
    title: 'BLOQUEADO',
    text: 'Compra foi ESTORNADO',
    color: '#e61818',
  },
  [ITicketStatus.WAITING_APPROVAL]: {
    title: 'BLOQUEADO',
    text: 'Ingresso em TRANSFERÊNCIA',
    color: '#f9f62f',
  },
};

export const ScanResult = ({
  setTicket,
  ticket,
  cancelRef,
  isOpen,
  onClose,
  decode,
}: ScanResultProps) => {
  if (!ticket) return null;

  const { ticketType, lot, owner, status } = ticket;

  const tt = `${ticketType?.name} ${lot?.name}`;
  const name = owner ? `${owner?.name} ${owner?.lastName}` : null;
  const cpf = owner?.cpf ? owner?.cpf : owner?.documentNumber ?? null;

  const close = () => {
    onClose();
    setTicket(null);
    decode();
  };

  const { title, text, color } = statusMap[status as ITicketStatus];

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={close} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent w='90%' bg='secondary.400'>
          <AlertDialogHeader>
            <Text
              color='#bbb'
              fontSize='lg'
              fontWeight='bold'
              textTransform='uppercase'
              textAlign='center'
            >
              Btix Informa
            </Text>
          </AlertDialogHeader>

          <AlertDialogBody fontSize='18px' fontWeight='500' textAlign='center'>
            <Text>
              Qr Code <b style={{ color: color }}>{title}</b>.
            </Text>

            <Text my='5px'>{text}</Text>

            {status === ITicketStatus.READY && (
              <>
                {name && (
                  <Text>
                    Nome: <b style={{ color: color }}>{name ?? 'Não encontrado'}</b>
                  </Text>
                )}

                {cpf && (
                  <Text>
                    CPF:{' '}
                    <b style={{ color: color }}>
                      {normalizeDocumentNumber(cpf) ?? 'Não encontrado.'}
                    </b>
                  </Text>
                )}
              </>
            )}

            {status !== ITicketStatus.CANCELED && (
              <Text>
                Ingresso: <b style={{ color: color }}>{tt}</b>
              </Text>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} bg={color} onClick={close} w='120px' h='60px'>
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
