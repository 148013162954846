import React from 'react';

import { AuthProvider } from './auth';

type ContextProps = {
  children?: React.ReactNode;
};
const Contexts: React.FC<ContextProps> = ({ children }) => {
  return (
    <>
      <AuthProvider>{children}</AuthProvider>
    </>
  );
};

export default Contexts;
