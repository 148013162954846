import { BrowserRouter } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';

import Contexts from './hooks';
import { Routes } from './routes';
import { theme } from './style/theme';

// Global Styles
import './style/global.css';

export default function App() {
  return (
    <BrowserRouter>
      <Contexts>
        <ChakraProvider resetCSS theme={theme}>
          <Routes />
        </ChakraProvider>
      </Contexts>
    </BrowserRouter>
  );
}
