import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IUser } from '../interfaces/interfaces';
import { signIn } from '../services/user';
import { AuthContextData, ICredentials } from './types';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);
type AuthProviderProps = {
  children?: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState<IUser | undefined | null>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const get = async () => {
      const storageUser = localStorage.getItem('@Btix:user');

      const user = storageUser && JSON.parse(storageUser);
      if (storageUser) {
        setUser(user);
      } else {
        setUser(null);
      }
    };

    get();
  }, []);

  const SignIn = async ({ password, email }: ICredentials) => {
    try {
      setIsLoading(true);

      const response = (await signIn(email, password)).data;
      setUser(response.user);

      localStorage.setItem('@Btix:token', response.token);
      localStorage.setItem('@Btix:user', JSON.stringify(response.user));
      localStorage.setItem('@Btix:id', response.user._id);

      navigate('/', { replace: true });
    } catch (error) {
      console.error(error);

      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = () => {
    setUser(null);

    localStorage.removeItem('@Btix:id');
    localStorage.removeItem('@Btix:user');
    localStorage.removeItem('@Btix:token');

    navigate('/');
  };

  return (
    <AuthContext.Provider
      value={{
        signed: user !== null,
        user,
        SignIn,
        signOut,
        setUser,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
