import api from './api';

export async function getMyEvents() {
  return api.get('/btix-events/producer/get-by-producer');
}

export async function getTokenTicket(req: string | undefined) {
  const { data } = await api.post(`ticket/obtain/token/${req}`);

  return data;
}

export async function scanTicket(eventId: string, token: string, type: 'Event' | 'BenefitProgram') {
  const { data } = await api.patch(
    `ticket/token/read-${type === 'Event' ? `ticket/${eventId}/${token}` : 'nft'}`,
  );

  return data;
}
