import { useRoutes } from 'react-router';

import { PageNotFound } from 'pages/404';
import { Home } from 'pages/Home';
import { Login } from 'pages/Login';
import { Scan } from 'pages/Scan';

import { useAuth } from '../hooks/auth';

const Authenticate = ({
  Item,
  authenticationPage,
}: {
  Item: JSX.Element | any;
  authenticationPage?: boolean;
}): JSX.Element => {
  const { signed } = useAuth();
  if (authenticationPage && signed) return <Home />;
  return signed ? <Item /> : <Login />;
};

export const Routes = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <Authenticate Item={Home} />,
    },
    {
      path: '/inicio',
      element: <Authenticate Item={Home} />,
    },
    {
      path: '/entrar',
      element: <Authenticate Item={Login} authenticationPage={true} />,
    },
    {
      path: '/escanear',
      element: <Authenticate Item={Scan} />,
    },
    {
      path: '/*',
      element: <PageNotFound />,
    },
  ]);

  return routes;
};
