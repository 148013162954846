import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react';

export const WarningText = ({ title, description }: { title: string; description: string }) => {
  return (
    <Alert
      status='warning'
      variant='subtle'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      color='secondary.500'
      borderRadius='10px'
    >
      <AlertIcon boxSize='40px' mr='0' />
      <AlertTitle mt='4' mb='1' fontSize='lg'>
        {title ?? 'Sem conteúdo'}
      </AlertTitle>
      <AlertDescription maxWidth='sm'>
        {description ?? 'Não há conteúdo disponível, reinicia a página se necessário.'}
      </AlertDescription>
    </Alert>
  );
};
