import { Box, Flex, Heading, Progress, Text } from '@chakra-ui/react';
import './style.css';

export const Loading = ({ text }: { text: string }) => {
  return (
    <Box w='100%' maxW='500px' h='200px' mx='auto' bg='secondary.400' p='6' borderRadius='10px'>
      <Flex justify='center' align='center' h='150px'>
        <Heading
          color='white'
          fontSize='24px'
          fontWeight='500'
          letterSpacing='.8px'
          textAlign='center'
          mb='6'
          display='flex'
        >
          {text}
          <Text as='span' ml='4px'>
            <div className='loading-container'>
              <div className='loading-animation'>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </div>
          </Text>
        </Heading>
      </Flex>

      <Progress size='xs' bg='#FD6244' borderRadius='20px' isIndeterminate />
    </Box>
  );
};
