import { Flex, Select } from '@chakra-ui/react';
import { Device } from 'interfaces/device';

interface SelectProps {
  devices: Device[];
  setDevice: (id: string) => void;
  currentDevice: string;
}

export const SelectVideo = ({ devices, currentDevice, setDevice }: SelectProps) => {
  const changeDevice = (device: string): void => {
    if (device === '') return;
    if (device !== currentDevice) setDevice(device);
  };

  return (
    <Flex px='6'>
      <Select
        variant='filled'
        maxW='500px'
        h='60px'
        mx='auto'
        placeholder='Selecione a câmera'
        mb='6'
        border='none'
        cursor='pointer'
        onChange={(device) => changeDevice(String(device.target.value))}
        defaultValue={currentDevice}
      >
        {devices.map((device: any) => (
          <option
            key={device.deviceId}
            value={device.deviceId}
            style={{ backgroundColor: '#4C4C4C', height: '60px' }}
          >
            {device.label}
          </option>
        ))}
      </Select>
    </Flex>
  );
};
