import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { Cards } from 'components/Cards';
import { Loading } from 'components/Loading';
import { useAuth } from 'hooks/auth';
import { IBenefitsProgram } from 'interfaces/benefit';
import { getBenefitProgramByProducer } from 'services/benefit';

import { Nav } from '../../components/Nav';
import { IEvent } from '../../interfaces/interfaces';
import { getMyEvents } from '../../services/ticket';

export const Home = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingBenefit, setIsLoadingBenefit] = useState<boolean>(true);

  const [events, setEvents] = useState<IEvent[]>([]);
  const [benefits, setBenefits] = useState<IBenefitsProgram[]>([]);

  const handleUnauthorizedError = (error: AxiosError) => {
    if (error.response?.status === 401) {
      signOut();
      navigate('/entrar');
    }
  };

  const getEvents = async () => {
    try {
      setIsLoading(true);
      const response = (await getMyEvents()).data;
      setEvents(response);
    } catch (error) {
      if (error instanceof AxiosError) {
        handleUnauthorizedError(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getBenefits = async () => {
    try {
      setIsLoadingBenefit(true);
      const responseBenefit: IBenefitsProgram[] = await getBenefitProgramByProducer();
      setBenefits(responseBenefit);
    } catch (error) {
      console.error('Erro ao requisitar benefícios', error);
    } finally {
      setIsLoadingBenefit(false);
    }
  };

  useEffect(() => {
    const isSigned = async () => {
      const storedUser = localStorage.getItem('@Btix:user');
      const user = storedUser && JSON.parse(storedUser);
      if (user) {
        await Promise.all([getEvents(), getBenefits()]);
      }
    };

    isSigned();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, signOut]);

  const styleTabList = {
    w: '200px',
    h: '60px',
    fontSize: '18px',
    fontWeight: '500',
    border: 'none',
    transition: '.2s',
    bg: 'secondary.400',
    _hover: {
      bg: 'secondary.300',
    },
  };

  return (
    <>
      <Nav />

      <Tabs variant='unstyled' w='100%' align='center' p='6'>
        <TabList gap={6}>
          <Tab _selected={{ bg: 'primary.500' }} {...styleTabList}>
            {events?.length > 1 ? 'Eventos Ativos' : 'Evento Ativo'}
          </Tab>
          <Tab _selected={{ bg: 'primary.500' }} {...styleTabList}>
            NFT
          </Tab>
        </TabList>
        <TabPanels mt='6'>
          <TabPanel p='0'>
            {isLoading ? (
              <Loading text='Carregando Eventos' />
            ) : (
              <Cards data={events} type='Event' />
            )}
          </TabPanel>
          <TabPanel p='0'>
            {isLoadingBenefit ? (
              <Loading text='Carregando Benefícios' />
            ) : (
              <Cards data={benefits} type='BenefitProgram' />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
