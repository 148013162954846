import { Card } from 'components/Card';
import { WarningText } from 'components/Warning/WarningText';
import { motion } from 'framer-motion';
import { IBenefitsProgram } from 'interfaces/benefit';
import { IEvent } from 'interfaces/interfaces';

export const Cards = ({
  data,
  type,
}: {
  data: IEvent[] | IBenefitsProgram[];
  type: 'Event' | 'BenefitProgram';
}) => {
  const title = `Sem ${type === 'Event' ? 'Evento' : 'Programa de Benefícios'}!`;
  const description = `No momento, não há nenhum ${
    type === 'Event' ? 'evento' : 'programa de benefícios'
  } em andamento. Caso você tenha alguma dúvida, por favor, entre em contato através do nosso e-mail de suporte: (suporte@btix.app).`;

  return (
    <>
      {data?.length ? (
        data?.map((dataContent: IEvent | IBenefitsProgram, index: number) => (
          <motion.div
            key={dataContent?._id + index}
            initial={{ x: -10, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <Card data={dataContent} type={type} />
          </motion.div>
        ))
      ) : (
        <WarningText title={title} description={description} />
      )}
    </>
  );
};
