import { Reading } from 'interfaces/reading';

import { STORED_KEY } from '../constants';

export const addReading = (reading: Reading): { status: number } => {
  const readingsString = localStorage.getItem(STORED_KEY) ?? '[]';
  const readings = JSON.parse(readingsString);

  readings.push(reading);

  localStorage.setItem(STORED_KEY, JSON.stringify(readings));

  return { status: 200 };
};

export const getReadings = (id?: string): Reading[] => {
  const readingsString = localStorage.getItem(STORED_KEY) ?? '[]';
  const readings = JSON.parse(readingsString);

  if (!readings) return [];
  if (id) return readings.filter((reading: Reading) => String(reading.event) === String(id)) ?? [];

  return readings;
};

export const clearReadings = (): { status: number } => {
  localStorage.removeItem(STORED_KEY);

  return { status: 200 };
};
