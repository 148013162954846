import { Box } from '@chakra-ui/react';

export const Dotted = () => {
  return (
    <Box
      boxSize={{ base: '200px', md: '300px' }}
      position='absolute'
      top='50%'
      left='50%'
      transform='translate(-50%, -50%)'
      zIndex='10'
      border='4px dashed orangered'
      overflow='hidden'
      style={{ transition: 'all 0.3s' }}
    />
  );
};
